import { useState } from "react";
import { Link } from 'react-router-dom';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import {
    EmailIcon,
    EmailShareButton,
    FacebookIcon,
    FacebookShareButton,
    TwitterIcon,
    TwitterShareButton,
    WhatsappIcon,
    WhatsappShareButton
  } from "react-share";

function SHARELINKS({ID}) {
    const [copied, setCopied] = useState(false);
    const [url1]= useState(`https://www.cellercertified.com/batchReport?BatchReportID=${ID}`)
    const [url2]= useState(`http://localhost:3001/batchReport?BatchReportID=${ID}`)
    const [url]= useState(url1)
const handleButtonClick = () => {    
    const myDiv = document.getElementById('main_share');
    if (myDiv) {myDiv.hidden =true;}
};
function handleCopy() {
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  }

    return ( 
        <div className="w-full h-full fixed z-50 bg-black bg-opacity-40" id="main_share" hidden={true}>
        <div className="flex justify-center items-center h-full ">
        <div className="bg-white flex flex-col justify-center items-center sm:w-[45%]  w-[80%] rounded-md shadow-2xl py-5">
        <h1 className="font-bold text-lg">Share Your Report</h1>
        <Link to={url}arget="_blank" className="text-sky-500 text-sm m-2 text-center ">{url}</Link>

        <CopyToClipboard text={url} onCopy={handleCopy}>
        <button className="">Copy Link</button>
      </CopyToClipboard>
      {copied && <span style={{ color: 'green' }}>Copied!</span>}
        <div className="m-2">
        <FacebookShareButton url={url} >
        <FacebookIcon size={32} round={true}/>
        </FacebookShareButton>

        <WhatsappShareButton url={url}>
        <WhatsappIcon size={32} round={true}/>
        </WhatsappShareButton>

        <TwitterShareButton url={url}>
        <TwitterIcon size={32} round={true}/>
        </TwitterShareButton>

        <EmailShareButton  url={url}>
        <EmailIcon size={32} round={true}/>
        </EmailShareButton>
       
        </div>
        <button className="bg-sky-400 text-white px-2 py-1 rounded-md shadow-sm " onClick={handleButtonClick} >CLOSE</button>
        </div>
        </div>
        </div>
         );
}

export default SHARELINKS;