import { useEffect, useRef,useState } from "react";
import LOGO from "../../images/new-barcode-logo.svg"
import Barcode from "react-barcode";
// import ReactToPrint from "react-to-print";
// import { formatDate } from "../scripts/services";
// import LABEL_1 from "./barcode_label_1";
// import LABEL_2 from "./bar_code_label_2";


export const pageStyle = `
@media print {
  html, body {
    margin: 0 !important;
    padding: 0 !important;
    overflow: hidden;
  }
}
@media print {
    .onprintlyout {
     
        border: 1px solid;
        border-radius: 65px;
        background-color: #fff !important;
        print-color-adjust: exact; 
    }
    .print_portait{
      width: 192px;
      height:384px;
    }

    .print_landscape{
      height : 192px;
      width:384px;
    }
    .print_medium{
      width:216px;
      height : 120px;

    }
    .pageBreak{
      page-break-before: always;
    }
}`;

export function PrintLabel({data}) {
console.log(data)

  const [layout, setlayout] = useState( "portrait");


   const ref = useRef();


    return ( 
        <div>
        <div className={`col-span-5  w-fit  rounded xl:col-span-2`}>
        {/* label conainer */}
        <div
          ref={ref}
          className={`
          
            rounded-lg  border-stroke bg-white  
          dark:border-strokedark dark:bg-boxdark
          ${
            (layout === "portrait" && "w-[288px] h-[384px]")
          }
          `}
        >
          {/* label print conainer */}
          <div
            className={`mb-2 onprintlyout rounded-lg border-b w-full h-full border-stroke  dark:border-strokedark relative`}
          >
            {/*Actual Label*/}

        
            <div className="flex flex-col items-center h-full leading-6">
      <img src={LOGO} alt="label logo" className="w-24 mt-4" />

      <div className="border-2 border-black-2 mx-2 mt-2 p-1">
        <h1 className="text-xs text-black-2 font-semibold">{data?.MANUFACTURER + " " + data?.MODEL_DESCRIPTION}</h1>
        <h1 className="text-xs text-black-2 font-semibold">{data?.POSSIBLE_CARRIER}</h1> 
      </div>

      <div className="w-fill h-fit p-2 mb-1">
        <h1>IMEI: {data?.IMEI}</h1>
        <Barcode height={15} width={1} value={data?.IMEI} displayValue={false} ></Barcode> 

        <h1 className="">SN: {data?.SERIAL_NUMBER}</h1>
        <Barcode height={15} width={0.7} value={data?.SERIAL_NUMBER} displayValue={false} ></Barcode> 

        <h1 className="">Internal ID: {data?.INTERNAL_REFERENCE_ID}</h1>
        <Barcode height={15} width={0.7} value={data?.INTERNAL_REFERENCE_ID} displayValue={false} ></Barcode> 
      </div>

     

      <div className="justify-between gap-x-4 mt-[-15px] text-xs text-black-2 text-center font-semibold z-9 mb-0">
            <h1>BlackList Status: {data?.BLACKLIST_STATUS}</h1>
            <h1>ICLOUD LOCK: {data?.ICLOUD_LOCK}</h1>
            <h1>Carrier Status: {data?.SIM_LOCK_STATUS}</h1>
            <h1>Date: {data?.DATE}</h1>
            <h1>User: {data?.USER}</h1>
      </div> 

      {/* <div className="text-xs text-black-2 w-full px-2 mt-0 font-semibold tracking-tighter">
        {(v19_19 && v19) && <h1>Carrier Status: {v19}</h1>}
        {(v16 && v16_16) && <h1>Blacklist Status: {v16}</h1>}
        {(v15_15 && v15) && <h1>Color: {v15}</h1>}
        {(v9_9 && v9) && <h1>BH: {v9}%</h1>}
        {(v10 && v10_10) && <h1>FMI: {v10_10}</h1>}
      </div> */}
      {/* {v20_20 && v20 && <div className="absolute text-[11px] tracking-tighter text-black-2 font-smemibold bottom-12 left-1"><span>Customer Id: {v20}</span></div>}
      {v18_18 && v18 && <div className="absolute text-[11px] text-black-2 font-smemibold bottom-9 left-1"><h1>User: {v18}</h1></div>}
      {v12_12 && <div className="absolute text-xs text-black-2 font-smemibold bottom-7 left-1"><h1>Date: {v12}</h1></div>}
      {v21_21 && <div className="w-34  h-7 absolute text-xs text-black-2 font-smemibold tracking-tighter bottom-1 leading-none left-1"><h1>Notes: {v21}</h1></div>}
      <div className="absolute right-1 bottom-2">
        <span className="text-black font-semibold text-xs flex justify-center">Celler</span>
        <QRCodeSVG value={`https://www.cellercertified.com/publicreport?UID=${v13}`} size={45}></QRCodeSVG>
        <span className="text-black font-semibold text-xs flex justify-center">Certified</span>
      </div> */}
    </div>
              {/* <LABEL_1
              v1={manufacturer_value + " " + Model_Description_value}
              v2={IMEI_value}
              v2_2={IMEI}
              v3={Grade_value}
              v3_3={Grade}
              v4={Price_value}
              v4_4={Price}
              v5={currency_symbol}
              v6={manufacturer_value}
              v6_6={manufacturer}
              v7={SerialNumber_value}
              v7_7={SerialNumber}
              v8={Carrier_value}
              v8_8={Carrier}
              v9={BatteryHealth_value}
              v9_9={BatteryHealth}
              v10={FMI}
              v10_10={FMI_value}
              v11={MDM}              
              v12={Datee_value}
              v12_12={Datee}
              v13={UID}
              v14={Ref_ID_value}
              v14_14={Ref_ID}
              v16={BlackList_Status_Value}
              v16_16={BlackList_Status}
              // v17={Vendor_Value}
              // v17_17={Vendor}
              v18={User_Value}
              v18_18={User}
              v19={CarrierStatus_value}
              v19_19={CarrierStatus}
              v20={Customer_id_value}
              v20_20={Customer_id}
              v21={Notes_value}
              v21_21={Notes}
              /> */}
     
          </div>

          
        </div>
        </div>
        </div> );
}

