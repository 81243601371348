import { useState, useRef, useEffect } from "react";
import {MaterialReactTable} from 'material-react-table'
import { Link, useLocation } from "react-router-dom";
import { LOGO1 } from "../../SVGs";
// import { Button } from "@mui/material";
import html2pdf  from "html2pdf.js";
// import { DownloadTwoTone } from "@mui/icons-material";
import PuffLoader from "react-spinners/PuffLoader";
import axios from 'axios'
// import INFO_TOP from '../../components/Info_TOP';
import INFO_TOP_BATCH from "../../components/Info_TOP_Batch";
import BATCH_INFO from "../../components/Batch_info";
import CHECKS from "../../components/Checks";
import DEVICE_STATUS from "../../components/Device_Status";
import CARRIER_DETAILS from "../../components/Carrier_Details";
import BUYBACKVALUE from "../../components/BuyBackValue";
import SHARELINKS from "../../components/share_link";
import Products from "../../components/Products"
import bg from "../../images/bg.jpg";
import QRCode from 'qrcode.react';
import logo2 from "../../images/logo_2.2.jpg";
// import ReportPDF from "./Report_PDF";
// import { modelName } from "../../../../cellercertified-server/Models/Report";

function Batch_Report({data, BatchReportID, Customerid,DATE, IMEI}) {


  const [loadingtext, setloadingtext] = useState("Loading...");
  useEffect(() => {
  const interval = setInterval(() => {
    const loading_div = document.getElementById("black");
    loading_div.classList.add("hidden");
  }, 2000);

  return () => clearInterval(interval);
}, []);

    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const UID = params.get('UID')
    // const BatchReportID = params.get('BatchReportID')
    // const server_ip = "api.cellercertified.com";
    // const API = `https://api.cellercertified.com/report/${UID}`;
    // const localApi = `https://api.cellercertified.com/get-batchreport/${BatchReportID}`
 

// console.log(BatchReportID, UID, location)

    const [Data, setData] = useState(data);
  
    const [IMEI2, setIMEI2] = useState("");
    const [MANU, setMANU] = useState("");
    const [SERIAL_NUMBER, setSERIAL_NUMBER] = useState("");
    const [MODEL_DESCRIPTION, setMODEL_DESCRIPTION] = useState("");
    const [MODEL_NUMBER, setMODEL_NUMBER] = useState("")
    const [MODEL] = useState("~");
    const [BUY_BACK_BVALUE, setBUY_BACK_BVALUE] = useState(1);  
    const [REPORTEDSRL, setREPORTEDSRL] = useState(true);  
    const [BLACKLIST_STATUS, setBLACKLIST_STATUS] = useState("");
    const [BLACKLIST_REASON, setBlaclistReason] = useState("~");
    const [BLACKLIST_BY, setBlacklistedBY] = useState("~");
    const [BLACKLISTED_COUNTRY, setBlacklistedCountry] = useState("~");
    const [BLACKLISTED_ON, setBlacklistedOn] = useState("~");
    const [ICLOUD_LOCK, setICLOUD_LOCK] = useState("");
    const [SIM_LOCK_STATUS, setSIM_LOCK_STATUS] = useState("");
    const [ESTIMATED_PURCHASE_DATE, setESTIMATED_PURCHASE_DATE] = useState("");
    const [WARRANTY_STATUS, setWARRANTY_STATUS] = useState("");
    const [LOANER_DEVICE, setLOANER_DEVICE] = useState("");
    const [REFURBISHED_DEVICE, setREFURBISHED_DEVICE] = useState("");
    const [PART_NUMBER, setPART_NUMBER] = useState("");
    const [A_NUMBER,setA_NUMBER]= useState("");
    const[DEMO_UNIT,setDEMO_UNIT] = useState("");
    const [REPLACED_DEVICE,setREPLACED_DEVICE] = useState("")
    const [REPLACEMENT_DEVICE,setREPLACEMENT_DEVICE] = useState("")
    const [ESN_STATUS, setESN_STATUS] = useState("")
    const [Device_Name, setDeviceName] = useState("");
    const [Modal_Name, setModalName] = useState("");
    const [Purchase_Country, setPurchaseCountry] = useState("");
    const [Operating_System, setOperatingSystem] = useState("");
  
  
    const [Loading, setloading] = useState(false);
    const [Uploading, setUploading] = useState(false);
    const [DownloadLink, setDownloadLink] = useState("~");
    const [ID, setID] = useState(UID);
    // const [DATE, setDate] = useState("~");
    const [TIME,setTime] = useState("~");
    const [ATT,setATT]= useState("~");
    const [TMOBILE,setTMOBILE]= useState("~");
    const [VERIZON,setVERIZON] = useState("~");
    const [POSSIBLE_CARRIER,setPOSSIBLE_CARRIER] = useState("~")
    const [capacity, setCapacity] = useState("~")

    function updateID(x) {setID(x);}
    // function updateDate(x) {setDate(x);}
    function updatedownloadlink(x) {setDownloadLink(x);}
    function updateuploading(x) {setUploading(x);}

    const Ref_Device_Status = useRef(null);
    const Ref_Carrier_Status = useRef(null);
    const Ref_More_Details = useRef(null);
    const Ref_Buy_Back_Guarantee = useRef(null);

    const [products, setProducts] = useState([])


    const Scrol_To_Device_Status = () => {
      Ref_Device_Status.current.scrollIntoView({ behavior: 'smooth' });
    };
    const Scrol_To_Carrier_Status = () => {
      Ref_Carrier_Status.current.scrollIntoView({ behavior: 'smooth' });
    };
    const Scrol_To_More_Details = () => {
      Ref_More_Details.current.scrollIntoView({ behavior: 'smooth' });
    };
    const Scrol_To_Buy_Back_Guarantee = () => {
      Ref_Buy_Back_Guarantee.current.scrollIntoView(
        {behavior: 'smooth' });
    };


    var requestOptions = {
      method: "GET",
      redirect: "follow",
     
    };
    // function extractCapacity(modelDescription) {
    //   // Assuming that the capacity is specified as a number followed by "GB" in the model description
    //   const capacityRegex = /(\d+)\s*GB/i;
    //   const match = modelDescription.match(capacityRegex);
    
    //   if (match && match[1]) {
    //     return parseInt(match[1], 10); // Parse the matched capacity as an integer
    //   } else {
    //     return null; // Return null if no capacity is found
    //   }
    //   }

    
//     const extractCapacity = (modelDescription) => {
//       const regex = /(\d+)GB/i  || /(\d+)GB[^0-9]?/i;
//       const match = modelDescription.match(regex);

//       if (match) {
//           return match[0];
//       } else {
//           return "N/A";
//       }
//   }

  const fetchProducts = async () => {
    console.log(Device_Name, Modal_Name, POSSIBLE_CARRIER, SIM_LOCK_STATUS, A_NUMBER, MODEL_NUMBER, MANU, capacity)
    if (
      ((Device_Name && Device_Name.trim() !== "") || (Modal_Name && Modal_Name.trim() !== "")) &&
      ((POSSIBLE_CARRIER && POSSIBLE_CARRIER.trim() !== "") ||
        (SIM_LOCK_STATUS && SIM_LOCK_STATUS.trim() !== "")) &&
      ((A_NUMBER && A_NUMBER.trim() !== "") ||
        (MODEL_NUMBER && MODEL_NUMBER.trim() !== "")) &&
      (MANU && MANU.trim() !== "") &&
      (capacity && capacity.trim() !== "")
    ) {
      let body = [
        { modelName: Device_Name || Modal_Name },
        { capacity: capacity },
        { manufacturer: MANU },
        {carrier: POSSIBLE_CARRIER || SIM_LOCK_STATUS},
        
      ];
  
      if (A_NUMBER && A_NUMBER.trim() !== "") {
        body.push({ aNumber: A_NUMBER });
      } else if (MODEL_NUMBER && MODEL_NUMBER.trim() !== "") {
        body.push({ modelNumber: MODEL_NUMBER });
      }
  
      try {
        const res = await axios.post('https://api.cellercertified.com/creatingProducts', body);
        console.log(body)
        console.log(res, "res");
      } catch (error) {
        console.error("Error making API call:", error);
      }
      fetchData();
    } else {
      console.log("Some required parameters are empty. API call not made.");
    }
  };
  

  const fetchData = async () => {
    try {
        const url = 'https://api.cellercertified.com/products';
        const response = await fetch(url);

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        setProducts(data);
    } catch (error) {
        console.error('Error fetching data:', error);
    }
};


let columns = [
  { header: "#", accessorKey: "index", Cell:({row}) => <div>{row.index+1}</div> ,enableEditing: false },
  {header: "IMEI#",accessorKey: "IMEI",enableEditing: false},
  {header: "Manufacturer",accessorKey: "MANUFACTURER",enableEditing: false},
  {header: "Model Name",accessorKey: "MODEL",enableEditing: false},
  {header: "BlackList Status",accessorKey: "BLACKLIST_STATUS",enableEditing: false,},
  {header: "Lock Status",accessorKey: "ICLOUD_LOCK",enableEditing: false},
  {header: "Carrier Status",accessorKey: "SIM_LOCK_STATUS",enableEditing: false},
  {header: "Internal ID",accessorKey:"INTERNAL_REFERENCE_ID"},
  {header: "Report",accessorKey: "UID",Cell:({row}) => 
  <a className="hover:underline text-blue-500 " href={`https://www.cellercertified.com/publicreport?UID=${row.original.UID}`} rel="noreferrer" target="_blank">Link</a>} ]

 //   useEffect(()=>{
    //   fetch(localApi, requestOptions, new URLSearchParams({UID:ID}))
    //   .then((response) => response.json())
    //   .then((result) => {
    //   console.log("result", result)
      // setData(data)
      // console.log(data, Data)
      //  setATT(data.data?.ATT) 
      //  setVERIZON(data.data?.VERIZON)
      //  setTMOBILE(data.data?.TMOBILE)
      //  setIMEI(data.data?.length);
      //  setSIM_LOCK_STATUS(data.data?.SIM_LOCK_STATUS);
      //   setIMEI2(data.IMEI2);
      //   setMANU(data?.MANU);
      //   setSERIAL_NUMBER(data.SERIAL_NUMBER);
      //   setMODEL_DESCRIPTION(data?.BatchReportID);
      //   setModalName(data.modelName);
      //   setMODEL_NUMBER(data.MODEL_NUMBER);
      //   setBLACKLIST_STATUS(data?.BLACKLIST_STATUS);
      //   setBlaclistReason(data?.BLACKLIST_REASON);
      //   setBlacklistedBY(data?.BLACKLISTED_BY);
      //   setBlacklistedCountry(data?.BLACKLISTED_COUNTRY);
      //   setBlacklistedOn(data?.BLACKLISTED_ON);
      //   setICLOUD_LOCK(data?.ICLOUD_LOCK); //no device loack reported cehck
      //   setSIM_LOCK_STATUS(data?.SIM_LOCK_STATUS); //no device loack reported
      //   setESTIMATED_PURCHASE_DATE(data?.ESTIMATED_PURCHASE_DATE);
      //   setWARRANTY_STATUS(data?.WARRANTY_STATUS);
      //   setLOANER_DEVICE(data.LOANER_DEVICE);
      //   setREFURBISHED_DEVICE(data.REFURBISHED_DEVICE);
      //   setPART_NUMBER(data.PART_NUMBER);
      //   setA_NUMBER(data.A_NUMBER);
        // setDEMO_UNIT(data.DEMO_UNIT);
        // setREPLACED_DEVICE(data.data.REPLACED_DEVICE);
        // setREPLACEMENT_DEVICE(data.data.REPLACEMENT_DEVICE);
        // setESN_STATUS(data.ESN_STATUS);
        // setDeviceName(data.MARKETING_NAME);
        // setModalName(data.MODEL);
        // setPurchaseCountry(data.data.PURCHASE_COUNTRY);
        // setOperatingSystem(data.data.OPERATING_SYSTEM);
        // setPOSSIBLE_CARRIER(data?.POSSIBLE_CARRIER);
        // setTime(data.TIME)
        // updateDate(data.Date);
        // setloading(true);
        // if (data.data.BLACKLIST_STATUS === " REPORTED STOLEN OR LOST") {
        //   setREPORTEDSRL(false);
        // }
        // console.log(data.Date, "before capacry extractions")
        
    //    setCapacity(extractCapacity(data.data.MODEL_DESCRIPTION))
    //   }).then(fetchProducts())
    //   .catch((error) => console.log("error", error));
    

      
  //  },[])

    // const [itemsToShow, setBreakPoints] = useState(3);

    // useEffect(() => {
    //     const calculateBreakPoints = () => {
    //       const windowWidth = window.innerWidth;
    //       let itemsToShow = [];
    //       if (windowWidth  >= 800) {
    //         itemsToShow = 3
    //       } else if (windowWidth >= 600) {
    //         itemsToShow = 2
    //       } else if (windowWidth >= 0) {
    //         itemsToShow = 1
    //       } else {
    //         itemsToShow = 1
    //       }
    
    //       setBreakPoints(itemsToShow);
    //     };
    //     calculateBreakPoints();
    //     window.addEventListener('resize', calculateBreakPoints);
    //     return () => {
    //       window.removeEventListener('resize', calculateBreakPoints);
    //     };
    //   }, []);

    // const filteredProducts = products?.length > 0 ? products?.filter((product) => {

    //     if(product.aNumber && product.aNumber === A_NUMBER && product.capacity === extractCapacity(MODEL_DESCRIPTION) ){
    //         console.log("filter with aNumber and capacity")
    //         return product;
    //     }else if(product.modelName === MODEL_DESCRIPTION){
    //         console.log("filter with model")
    //         return product;
    //     }
    //     else if(product.modelNumber && product.modelNumber === MODEL_NUMBER){
    //         console.log("filter with model number")
    //         return product;
    //     }
  
    // }) : []

  //   function Download(){
  //     var element = document.getElementById('pdf-content');
  // var opt = {
  //   margin:       0.5,
  //   filename:     `${IMEI}.pdf`,
  //   image:        { type: 'jpeg', quality: 0.98 },
  //   html2canvas:  { scale: 2 },
  //   jsPDF:        { unit: 'in', format: 'letter', orientation: 'portrait' }
  // };
  // html2pdf().from(element).set(opt).save();
  
  // }
    console.log(data, BatchReportID, Customerid, IMEI, DATE)

    return (
      <>
      <div className=" w-full">
        <div style={{lineHeight: 0}} >
          <div  className="mx-5" style={{lineHeight: 0}} >
                    
                <div className="border-b INFO_TOP_CNT flex flex-row pb-2 w-[100%] justify-between">

                  <div className=" flex items-center  w-[40vw]">
                    <img src={logo2} alt="logo2" className="w-[80%] h-[80%]"/>
                  </div>
                  <div className="flex flex-row items-center justify-end  w-[60vw]">
                      <div >
                        <QRCode value={`https://www.cellercertified.com/batchReport?BatchReportID=${BatchReportID}`}  style={{width:60,height:60}}/>
                      </div>

                      <div className="flex flex-col  justify-center px-2 sm:px-3  md:px-1 text-xs md:text-[1.2vw] lg:text-xs">
                      <div className=" font-medium  text-sky-600 " >
                          Print this link
                        </div>

                        <div className=" font-medium  text-sky-600   " >
                          Share this link 
                        </div>
                        <div className=" text-slate-900 font-medium"> ID: {BatchReportID}</div>
                        <div className="text-slate-900 font-medium">Date: {DATE}
                        </div>
                      </div>
                  </div>
                </div>
       
          <BATCH_INFO
            a={BatchReportID}
            b={Customerid}
            c={IMEI}
            c2={IMEI2}
            d={SERIAL_NUMBER}
            e={Modal_Name}
            f={Purchase_Country}
            g={PART_NUMBER}
            h={A_NUMBER}
            i={Operating_System}
            j={MODEL_NUMBER}
            k={Device_Name}
            data={data}
          />

      

      

          <MaterialReactTable
            data={data.map((item) => item.original ? item.original : item)} 
      columns={columns.map(c => ({...c,size: 5,minSize: 10}))}

      positionToolbarAlertBanner="bottom"
      // initialState={{density: 'compact' }}
      enableBottomToolbar={false}
      enableTopToolbar={false} 
      enableColumnActions={false}      
      enableDensityToggle={false} 
      enableFullScreenToggle={false} 
      enableFilters={false} 
      enablePagination={false}
      enableRowSelection={false}
      enableSorting={false}

        
      muiTableBodyCellEditTextFieldProps={{variant: 'filled'}} 
      
      muiTableContainerProps={{sx:{background:'transparent',borderRadius:'8px', }}}
      muiTableProps={{sx:{padding:'',background:'transparent'}}}
      muiTablePaperProps={{sx:{background:'transparent'}}}
      muiTableBodyCellProps={({cell,column})=> ({
        
        align:'center',
        padding: '0px',
       
        sx:{
          border:'0px', 
          borderBlockEnd:'1px solid rgba(0,0,0,0.1)',
          color:'rgba(100,100,100,1)',
          fontSize: '12px',
          padding: '8px',
          background:( 
            cell.getValue() ==="CLEAN")?'rgba(0, 255, 81,0.5)':''|| 
            ( cell.getValue() ==="BAD")?'rgba(255, 0, 25,0.4)':''|| 
            ( cell.getValue() ==="BLACKLISTED")?'rgba(255, 0, 25,0.4)':''||
            ( cell.getValue() ==="OFF")? 'rgba(0, 255, 81,0.5)': ''||
            ( cell.getValue() ==="ON")? 'rgba(255, 0, 25,0.4)': ''||
            ( cell.getValue() ==="LOCKED")? 'rgba(255, 0, 25,0.4)': ''||
            ( cell.getValue() ==="UNLOCKED")? 'rgba(0, 255, 81,0.5)': ''||
            ( cell.getValue() ==="OUT OF WARRANTY")? 'rgba(255, 0, 25,0.4)': ''||
            ( cell.getValue() ==="WARRANTY EXPIRED")? 'rgba(255, 0, 25,0.4)': ''||
            ( cell.getValue() ==="LIMITED WARRANTY")? 'rgba(244, 255, 151, 0.8)': ''||
            ( cell.getValue() ==="APPLE CARE")? 'rgba(0, 255, 81,0.5)': ''

        }
      })}
      muiTableHeadCellProps={{sx:{ background:'rgb(0 174 239)',color:'white',border: '1px solid white',fontSize: '14px',},align:'center'}}
      muiTableHeadRowProps={{sx:{}}}
      muiTableBodyRowProps={{sx:{background:'transparent'},hover:false}}
      muiTableBodyProps={{sx:{background:'transparent'}}}
      muiTopToolbarProps={{sx:{background:'transparent'}}}
      muiBottomToolbarProps={{sx:{background:'transparent'}}}
          />


  
        </div>
        
        </div>
        </div>
        </>
        
        );
}

  function HEADER({ fun_bbg, fun2, fun1, REF }) {
    return (
      <div ref={REF} className="HEADER bg-transparent z-20">
        {/* <div className=" w-[34%] flex items-center justify-center object-scale-down ">
          <div onClick={() => {fun1()}} className="hover:cursor-pointer text-center text-xs font-bold text-white">
            DEVICE HISTORY REPORT
          </div>
        </div>
        <div className=" w-[35%] flex items-center justify-center object-contain ">
          <div onClick={() => { fun2() }} className="hover:cursor-pointer text-center text-xs font-bold text-white ">
            WARRANTY INFORMATION
          </div>
        </div>
        <div className=" w-[30%] flex items-center justify-center object-contain ">
          <div className="hover:cursor-pointer text-center text-xs font-bold text-white " onClick={() => { fun_bbg() }}>
            BUYBACK GUARANTEE
          </div>
        </div> */}
      </div>
    );
  }
  
  function LOADING({ loadingtext }) {
    return (
      <div
        id="black"
        className="w-full h-full z-50 fixed  items-center justify-center flex flex-col"
        style={{ backgroundColor: "white", marginTop: "-50px" }}
      >
        <PuffLoader
          color={"skyblue"}
          loading={true}
          size={300}
          aria-label="Loading Spinner"
          data-testid="loader"
          cssOverride={{ position: "absolute" }}
        />
  
        <LOGO1 h={53} />
        <h1>{loadingtext}</h1>
      </div>
    );
  }
  
export default Batch_Report;
