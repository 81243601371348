import { useState, useRef, useEffect } from "react";
import {MaterialReactTable} from 'material-react-table'
import { Link, useLocation } from "react-router-dom";
import { LOGO1 } from "../../SVGs";
// import { Button } from "@mui/material";
import html2pdf  from "html2pdf.js";
// import { DownloadTwoTone } from "@mui/icons-material";
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import PuffLoader from "react-spinners/PuffLoader";
import axios from 'axios'
// import INFO_TOP from '../../components/Info_TOP';
import INFO_TOP_BATCH from "../../components/Info_TOP_Batch";
import BATCH_INFO from "../../components/Batch_info";
import CHECKS from "../../components/Checks";
import DEVICE_STATUS from "../../components/Device_Status";
import CARRIER_DETAILS from "../../components/Carrier_Details";
import BUYBACKVALUE from "../../components/BuyBackValue";
import SHARELINKS from "../../components/share_link";
import Products from "../../components/Products"
import bg from "../../images/bg.jpg";
import Batch_Report_PDF from "./Batch_Report_PDF";
import { PrintLabel, pageStyle } from "./Print_Label_Template";
import { styled } from '@mui/material/styles';
import { QrCodeScannerTwoTone } from '@mui/icons-material';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import ReactToPrint from "react-to-print";
import { saveAs } from "file-saver";
const XLSX = require('xlsx');
// import ReportPDF from "./Report_PDF";
// import { modelName } from "../../../../cellercertified-server/Models/Report";

const ToolTip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    boxShadow: theme.shadows[1],
    fontSize: 11,
    lineHeight: '1.5rem'
  },
}));



function Batch_Report() {


  const [loadingtext, setloadingtext] = useState("Loading...");
  useEffect(() => {
  const interval = setInterval(() => {
    const loading_div = document.getElementById("black");
    loading_div.classList.add("hidden");
  }, 2000);

  return () => clearInterval(interval);
}, []);

    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const UID = params.get('UID')
    const BatchReportID = params.get('BatchReportID')
    const server_ip = "api.cellercertified.com";
    const API = `https://api.cellercertified.com/report/${UID}`;
    const localApi = `https://api.cellercertified.com/get-batchreport/${BatchReportID}`
 

console.log(BatchReportID, UID, location)

    const [data, setData] = useState([]);
    const [IMEI, setIMEI] = useState("");
    const [IMEI2, setIMEI2] = useState("");
    const [MANU, setMANU] = useState("");
    const [SERIAL_NUMBER, setSERIAL_NUMBER] = useState("");
    const [MODEL_DESCRIPTION, setMODEL_DESCRIPTION] = useState("");
    const [MODEL_NUMBER, setMODEL_NUMBER] = useState("")
    const [MODEL] = useState("~");
    const [BUY_BACK_BVALUE, setBUY_BACK_BVALUE] = useState(1);  
    const [REPORTEDSRL, setREPORTEDSRL] = useState(true);  
    const [BLACKLIST_STATUS, setBLACKLIST_STATUS] = useState("");
    const [BLACKLIST_REASON, setBlaclistReason] = useState("~");
    const [BLACKLIST_BY, setBlacklistedBY] = useState("~");
    const [BLACKLISTED_COUNTRY, setBlacklistedCountry] = useState("~");
    const [BLACKLISTED_ON, setBlacklistedOn] = useState("~");
    const [ICLOUD_LOCK, setICLOUD_LOCK] = useState("");
    const [SIM_LOCK_STATUS, setSIM_LOCK_STATUS] = useState("");
    const [ESTIMATED_PURCHASE_DATE, setESTIMATED_PURCHASE_DATE] = useState("");
    const [WARRANTY_STATUS, setWARRANTY_STATUS] = useState("");
    const [LOANER_DEVICE, setLOANER_DEVICE] = useState("");
    const [REFURBISHED_DEVICE, setREFURBISHED_DEVICE] = useState("");
    const [PART_NUMBER, setPART_NUMBER] = useState("");
    const [A_NUMBER,setA_NUMBER]= useState("");
    const[DEMO_UNIT,setDEMO_UNIT] = useState("");
    const [REPLACED_DEVICE,setREPLACED_DEVICE] = useState("")
    const [REPLACEMENT_DEVICE,setREPLACEMENT_DEVICE] = useState("")
    const [ESN_STATUS, setESN_STATUS] = useState("")
    const [Device_Name, setDeviceName] = useState("");
    const [Modal_Name, setModalName] = useState("");
    const [Purchase_Country, setPurchaseCountry] = useState("");
    const [Operating_System, setOperatingSystem] = useState("");
  
  
    const [Loading, setloading] = useState(false);
    const [Uploading, setUploading] = useState(false);
    const [DownloadLink, setDownloadLink] = useState("~");
    const [ID, setID] = useState(UID);
    const [DATE, setDate] = useState("~");
    const [TIME,setTime] = useState("~");
    const [ATT,setATT]= useState("~");
    const [TMOBILE,setTMOBILE]= useState("~");
    const [VERIZON,setVERIZON] = useState("~");
    const [POSSIBLE_CARRIER,setPOSSIBLE_CARRIER] = useState("~")
    const [capacity, setCapacity] = useState("~")
    const [print_label_data, set_Print_Label_Data] = useState([])

    function updateID(x) {setID(x);}
    function updateDate(x) {setDate(x);}
    function updatedownloadlink(x) {setDownloadLink(x);}
    function updateuploading(x) {setUploading(x);}

    const Ref_Device_Status = useRef(null);
    const Ref_Carrier_Status = useRef(null);
    const Ref_More_Details = useRef(null);
    const Ref_Buy_Back_Guarantee = useRef(null);
    const ref2 = useRef();

    const [products, setProducts] = useState([])


    const Scrol_To_Device_Status = () => {
      Ref_Device_Status.current.scrollIntoView({ behavior: 'smooth' });
    };
    const Scrol_To_Carrier_Status = () => {
      Ref_Carrier_Status.current.scrollIntoView({ behavior: 'smooth' });
    };
    const Scrol_To_More_Details = () => {
      Ref_More_Details.current.scrollIntoView({ behavior: 'smooth' });
    };
    const Scrol_To_Buy_Back_Guarantee = () => {
      Ref_Buy_Back_Guarantee.current.scrollIntoView(
        {behavior: 'smooth' });
    };


    var requestOptions = {
      method: "GET",
      redirect: "follow",
     
    };

    
    const extractCapacity = (modelDescription) => {
      const regex = /(\d+)GB/i  || /(\d+)GB[^0-9]?/i;
      const match = modelDescription.match(regex);

      if (match) {
          return match[0];
      } else {
          return "N/A";
      }
  }

  const fetchProducts = async () => {
    console.log(Device_Name, Modal_Name, POSSIBLE_CARRIER, SIM_LOCK_STATUS, A_NUMBER, MODEL_NUMBER, MANU, capacity)
    if (
      ((Device_Name && Device_Name.trim() !== "") || (Modal_Name && Modal_Name.trim() !== "")) &&
      ((POSSIBLE_CARRIER && POSSIBLE_CARRIER.trim() !== "") ||
        (SIM_LOCK_STATUS && SIM_LOCK_STATUS.trim() !== "")) &&
      ((A_NUMBER && A_NUMBER.trim() !== "") ||
        (MODEL_NUMBER && MODEL_NUMBER.trim() !== "")) &&
      (MANU && MANU.trim() !== "") &&
      (capacity && capacity.trim() !== "")
    ) {
      let body = [
        { modelName: Device_Name || Modal_Name },
        { capacity: capacity },
        { manufacturer: MANU },
        {carrier: POSSIBLE_CARRIER || SIM_LOCK_STATUS},
        
      ];
  
      if (A_NUMBER && A_NUMBER.trim() !== "") {
        body.push({ aNumber: A_NUMBER });
      } else if (MODEL_NUMBER && MODEL_NUMBER.trim() !== "") {
        body.push({ modelNumber: MODEL_NUMBER });
      }
  
      try {
        const res = await axios.post('https://api.cellercertified.com/creatingProducts', body);
        console.log(body)
        console.log(res, "res");
      } catch (error) {
        console.error("Error making API call:", error);
      }
      fetchData();
    } else {
      console.log("Some required parameters are empty. API call not made.");
    }
  };
  

  const fetchData = async () => {
    try {
        const url = 'https://api.cellercertified.com/products';
        const response = await fetch(url);

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        setProducts(data);
    } catch (error) {
        console.error('Error fetching data:', error);
    }
};


let columns = [
  { header: "#", accessorKey: "index", Cell:({row}) => <div>{row.index+1}</div> ,enableEditing: false },
  {header: "IMEI#",accessorKey: "IMEI",enableEditing: false},
  {header: "Manufacturer",accessorKey: "MANUFACTURER",enableEditing: false},
  {header: "Model Name",accessorKey: "MODEL",enableEditing: false},
  {header: "BlackList Status",accessorKey: "BLACKLIST_STATUS",enableEditing: false,},
  {header: "Lock Status",accessorKey: "ICLOUD_LOCK",enableEditing: false},
  {header: "Carrier Status",accessorKey: "SIM_LOCK_STATUS",enableEditing: false},
  {header: "Internal ID",accessorKey:"INTERNAL_REFERENCE_ID"},
  {header: "Report",accessorKey: "UID",Cell:({row}) => 
  <a className="hover:underline text-blue-500 " href={`https://www.cellercertified.com/publicreport?UID=${row.original.UID}`} rel="noreferrer" target="_blank">Link</a>} ]

    useEffect(()=>{
      fetch(localApi, requestOptions, new URLSearchParams({UID:ID}))
      .then((response) => response.json())
      .then((result) => {
      console.log("result", result)
      setData(result.data.Data)
       setATT(result?.ATT) 
       setVERIZON(result?.VERIZON)
       setTMOBILE(result?.TMOBILE)
       setIMEI(result.data.Data.length);
       setSIM_LOCK_STATUS(result?.SIM_LOCK_STATUS);
        setIMEI2(result.IMEI2);
        setMANU(result.data.CustomerID);
        setSERIAL_NUMBER(result.SERIAL_NUMBER);
        setMODEL_DESCRIPTION(result.data.BatchReportID);
        setModalName(result.modelName);
        setMODEL_NUMBER(result.MODEL_NUMBER);
        setBLACKLIST_STATUS(result.BLACKLIST_STATUS);
        setBlaclistReason(result.BLACKLIST_REASON);
        setBlacklistedBY(result.BLACKLISTED_BY);
        setBlacklistedCountry(result.BLACKLISTED_COUNTRY);
        setBlacklistedOn(result.BLACKLISTED_ON);
        setICLOUD_LOCK(result.ICLOUD_LOCK); //no device loack reported cehck
        setSIM_LOCK_STATUS(result.SIM_LOCK_STATUS); //no device loack reported
        setESTIMATED_PURCHASE_DATE(result.ESTIMATED_PURCHASE_DATE);
        setWARRANTY_STATUS(result.WARRANTY_STATUS);
        setLOANER_DEVICE(result.LOANER_DEVICE);
        setREFURBISHED_DEVICE(result.REFURBISHED_DEVICE);
        setPART_NUMBER(result.PART_NUMBER);
        setA_NUMBER(result.A_NUMBER);
        setDEMO_UNIT(result.DEMO_UNIT);
        setREPLACED_DEVICE(result.REPLACED_DEVICE);
        setREPLACEMENT_DEVICE(result.REPLACEMENT_DEVICE);
        setESN_STATUS(result.ESN_STATUS);
        setDeviceName(result.MARKETING_NAME);
        setModalName(result.MODEL);
        setPurchaseCountry(result.PURCHASE_COUNTRY);
        setOperatingSystem(result.OPERATING_SYSTEM);
        setPOSSIBLE_CARRIER(result?.POSSIBLE_CARRIER);
        setTime(result.TIME)
        updateDate(result.data.Date);
        setloading(true);
        if (result.BLACKLIST_STATUS === " REPORTED STOLEN OR LOST") {
          setREPORTEDSRL(false);
        }
        console.log(result.data.Date, "before capacry extractions")
        
       setCapacity(extractCapacity(result.MODEL_DESCRIPTION))
      }).then(fetchProducts())
      .catch((error) => console.log("error", error));
    

      
    },[MODEL_DESCRIPTION])


    const handleExportRows = (rows) => {
      console.log("exporting ...")
      const mappedRows = rows.map((row) => {
        const R = { ...row._valuesCache };
        return R;
      });
  
      const headerRow = columns.map((column) => column.header)
      const data = [headerRow, ...mappedRows.map((row) => Object.values(row))];
  
      const worksheet = XLSX.utils.aoa_to_sheet(data);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
  
      const buffer = XLSX.write(workbook, { type: 'buffer', bookType: 'xlsx' });
      const DATE = Date.now();
      saveAs(new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }), "BATCH_REPORT_"+ BatchReportID+ "_" + DATE + ".xlsx");
  
    };

    function Download(){
      var element = document.getElementById('pdf-public-content');
  var opt = {
    margin:       0.1,
    filename:     `CellerCertifiedBatchReport_${BatchReportID}_${DATE}.pdf`,
    image:        { type: 'jpeg', quality: 0.98 },
    html2canvas:  { scale: 2 },
    jsPDF:        { unit: 'in', format: 'letter', orientation: 'landscape' }
  };
  html2pdf().from(element).set(opt).save();
  
  }
console.log(print_label_data)
    return (
      <>
            <LOADING loadingtext={loadingtext} />

      <SHARELINKS ID={BatchReportID}/>
         <div >
         
        <div  className="App mt-10">
        <img  src={bg} alt="bg img" className="absolute w-full h-96 print:hidden" />      
      <HEADER REF={Ref_More_Details} fun_bbg={Scrol_To_Buy_Back_Guarantee} fun2={Scrol_To_Device_Status} fun1={Scrol_To_More_Details}/>
        <div
          
          className="information_cnt z-10 w-[95%] sm:w-[75%] md:w-[50%] lg:w-[70%]     m-2 sm:m-0 p-4 sm:p-7 "
        >         
          <INFO_TOP_BATCH
            ID={BatchReportID}
            Date={DATE}
            Download={Download}
            data={data}
            link={`https://www.cellercertified.com/batchReport?BatchReportID=${BatchReportID}`}
            Upload={Uploading}
           
          />
                 <div className="hidden">
           <div id="pdf-public-content">
           <Batch_Report_PDF data={data} BatchReportID={BatchReportID} DATE={DATE} Customerid={MANU} IMEI={IMEI}/>
           </div>
          </div>
          <BATCH_INFO
            a={MODEL_DESCRIPTION}
            b={MANU}
            c={IMEI}
            c2={IMEI2}
            d={SERIAL_NUMBER}
            e={Modal_Name}
            f={Purchase_Country}
            g={PART_NUMBER}
            h={A_NUMBER}
            i={Operating_System}
            j={MODEL_NUMBER}
            k={Device_Name}
            data={data}
          />
      <div className="hidden print:block " ref={ref2}>
         <PrintLabel dataa={data} data={print_label_data} BatchReportID={BatchReportID} DATE={DATE} Customerid={MANU} total={IMEI}/> 
      </div>
          <MaterialReactTable
            data={data.map((item) => item.original ? item.original : item)} 
            columns={columns.map(c => ({...c,size: 5,minSize: 10}))}

            positionToolbarAlertBanner="bottom"
        enableFullScreenToggle={false}
        enableRowSelection={true}
        enableStickyHeader
        editingMode="modal"
        enableEditing

        // Pagination
        enablePagination={true}
        initialState={{
          density: 'compact',
          pagination: { pageIndex: 0, pageSize: 10 },
          // showColumnFilters: true,
          // showGlobalFilter: true 
        }}
        muiTablePaginationProps={{
          rowsPerPageOptions: [10, 25, 50, 100, 200, 250, 500],
          showFirstButton: true,
          showLastButton: true,
          SelectProps: {
            native: true
          },
          labelRowsPerPage: 'Rows per pages'
        }}

            muiTableBodyCellEditTextFieldProps={{variant: 'filled'}} 
      
            muiTableContainerProps={{sx:{background:'transparent',borderRadius:'8px', }}}
            muiTableProps={{sx:{padding:'',background:'transparent'}}}
            muiTablePaperProps={{sx:{background:'transparent'}}}
            muiTableBodyCellProps={({cell,column})=> ({
              
              align:'center',
              padding: '0px',
            
              sx:{
                border:'0px', 
                borderBlockEnd:'1px solid rgba(0,0,0,0.1)',
                color:'rgba(100,100,100,1)',
                fontSize: '12px',
                padding: '8px',
                background:( 
                  cell.getValue() ==="CLEAN")?'rgba(0, 255, 81,0.5)':''|| 
                  ( cell.getValue() ==="BAD")?'rgba(255, 0, 25,0.4)':''|| 
                  ( cell.getValue() ==="BLACKLISTED")?'rgba(255, 0, 25,0.4)':''||
                  ( cell.getValue() ==="OFF")? 'rgba(0, 255, 81,0.5)': ''||
                  ( cell.getValue() ==="ON")? 'rgba(255, 0, 25,0.4)': ''||
                  ( cell.getValue() ==="LOCKED")? 'rgba(255, 0, 25,0.4)': ''||
                  ( cell.getValue() ==="UNLOCKED")? 'rgba(0, 255, 81,0.5)': ''||
                  ( cell.getValue() ==="OUT OF WARRANTY")? 'rgba(255, 0, 25,0.4)': ''||
                  ( cell.getValue() ==="WARRANTY EXPIRED")? 'rgba(255, 0, 25,0.4)': ''||
                  ( cell.getValue() ==="LIMITED WARRANTY")? 'rgba(244, 255, 151, 0.8)': ''||
                  ( cell.getValue() ==="APPLE CARE")? 'rgba(0, 255, 81,0.5)': ''

              }
            })}
            muiTableHeadCellProps={{sx:{ background:'rgb(0 174 239)',color:'white',border: '1px solid white',fontSize: '16px',},align:'center'}}
            muiTableHeadRowProps={{sx:{}}}
            muiTableBodyRowProps={{sx:{background:'transparent'},hover:false}}
            muiTableBodyProps={{sx:{background:'transparent'}}}
            muiTopToolbarProps={{sx:{background:'transparent'}}}
            muiBottomToolbarProps={{sx:{background:'transparent'}}}



            renderRowActions={({ row, table }) => (
          <span className="w-52 " >

            <span className=" w-fit h-fit" onPointerOver={() => { set_Print_Label_Data(row.original) }}>
              <ReactToPrint
                trigger={() => <ToolTip title="Print Barcode"><QrCodeScannerTwoTone /></ToolTip>}
                content={() => ref2.current}
                pageStyle={pageStyle}
              />
            </span>

     
          </span>)}


            renderTopToolbarCustomActions={({ table }) => (
          <div >
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-3">
              <button
                className="px-5 py-2 disabled:text-gray-800 disabled:bg-gray-300 text-white bg-[#09b0ef]  rounded-md transition-all active:bg-black"
                disabled={!table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()}
                onClick={() => handleExportRows(table.getSelectedRowModel().rows)}
                variant="contained">
                <FileDownloadIcon/>
                Export as XLSX
              </button>
            </div>

          </div>

        )}
                />
        </div>
        
        </div>
        </div>
        </>
        
        );
}

  function HEADER({ fun_bbg, fun2, fun1, REF }) {
    return (
      <div ref={REF} className="HEADER bg-transparent z-20">

      </div>
    );
  }
  
  function LOADING({ loadingtext }) {
    return (
      <div
        id="black"
        className="w-full h-full z-50 fixed  items-center justify-center flex flex-col"
        style={{ backgroundColor: "white", marginTop: "-50px" }}
      >
        <PuffLoader
          color={"skyblue"}
          loading={true}
          size={300}
          aria-label="Loading Spinner"
          data-testid="loader"
          cssOverride={{ position: "absolute" }}
        />
  
        <LOGO1 h={53} />
        <h1>{loadingtext}</h1>
      </div>
    );
  }
  
export default Batch_Report;
